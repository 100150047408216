import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import SaveButton from '@/components/SaveButton.vue';
import { newFornecedor } from '@/interfaces/fornecedor';
import { fornecedorStore } from '@/utils/store-accessor';
import { validateCPF } from '@/utils';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
Validator.extend('cpf', {
    getMessage(field, params, data) {
        return `${field} deve ser um CPF válido!`;
    },
    validate(cpf) {
        return !!validateCPF(cpf) && cpf;
    },
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.id = '';
        this.cnpjSelected = true;
        this.entityObject = newFornecedor();
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        if (this.id) {
            await fornecedorStore.updateFornecedor(this.entityObject);
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            await this.$router.push({ name: 'main-fornecedores' });
            return;
        }
        await fornecedorStore.createFornecedor(this.entityObject);
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
        await this.$router.push({ name: 'main-fornecedores' });
    }
    async mounted() {
        this.entityObject = newFornecedor();
        if (this.$router.currentRoute.params.id) {
            this.id = this.$router.currentRoute.params.id;
            this.entityObject = await fornecedorStore.getFornecedor(+this.id);
        }
    }
};
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SaveButton,
        },
    })
], Create);
export default Create;
