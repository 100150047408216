var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Fornecedores")])]),_c('v-card-subtitle',[_vm._v("Criar Registro")]),_c('v-card-text',[[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Nome Fantasia","vid":"nome_fantasia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":"Nome Fantasia","error-messages":errors},model:{value:(_vm.entityObject.nome_fantasia),callback:function ($$v) {_vm.$set(_vm.entityObject, "nome_fantasia", $$v)},expression:"entityObject.nome_fantasia"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Razão Social","vid":"razao_social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":"Razão social","error-messages":errors},model:{value:(_vm.entityObject.razao_social),callback:function ($$v) {_vm.$set(_vm.entityObject, "razao_social", $$v)},expression:"entityObject.razao_social"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-radio-group',{staticStyle:{"margin-top":"30px"},attrs:{"row":""},model:{value:(_vm.cnpjSelected),callback:function ($$v) {_vm.cnpjSelected=$$v},expression:"cnpjSelected"}},[_c('v-radio',{attrs:{"label":"CPF","value":false}}),_c('v-radio',{attrs:{"label":"CNPJ","value":true}})],1),(_vm.cnpjSelected)?_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"CNPJ","vid":"cnpj","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],attrs:{"label":"CNPJ","type":"text","error-messages":errors},model:{value:(_vm.entityObject.cnpj),callback:function ($$v) {_vm.$set(_vm.entityObject, "cnpj", $$v)},expression:"entityObject.cnpj"}})]}}],null,true)})],1):_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"CPF","vid":"cnpj","rules":{required: true, cpf: _vm.cnpjSelected ? false : true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"label":"CPF","type":"text","error-messages":errors},model:{value:(_vm.entityObject.cnpj),callback:function ($$v) {_vm.$set(_vm.entityObject, "cnpj", $$v)},expression:"entityObject.cnpj"}})]}}],null,true)})],1)],1)]],2),_c('v-card-actions',[_c('save-button',{ref:"saveButton",on:{"submit":_vm.submit}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }